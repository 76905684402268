import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import store from '@/store/store.js';
import division from '@/components/cross-bar.vue'
import centerS from '@/components/centerS.vue'
import MapContainer from '@/components/MapContainer.vue'
import M_mapContainer from '@/components/M-mapContainer.vue'
import api from '@/api'
import app from './app.vue'
// 可以在main.js文件中引入，也可以在页面单独引入
import VueTouch from "vue-touch";
import 'video.js/dist/video-js.css'  //videojs样式
import Meta from 'vue-meta'
import { Swipe, SwipeItem } from 'vant';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Popup } from 'vant';
import { Search } from 'vant';
import { Cell } from 'vant';
import { Lazyload } from 'vant';
import { NoticeBar } from 'vant';
import DeviceDetectorPlugin from 'vue-device-detector';
import 'aos/dist/aos.css'
import AOS from 'aos'
import "swiper/swiper-bundle.css";
import * as echarts from 'echarts';
// import '@/view//mock/index.js'
import *  as loopShowTooltip from "@/util/echarts-auto-tooltip.js" // 文件位置参考自己的位置
Vue.prototype.loopShowTooltip = loopShowTooltip
Vue.prototype.$echarts = echarts
Vue.use(AOS)
AOS.init();
Vue.use(DeviceDetectorPlugin);
Vue.use(NoticeBar);
Vue.use(Lazyload);
Vue.use(Search);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Meta);
Vue.use(ElementUI);
Vue.component('division', division)
Vue.component('centerS', centerS)
Vue.component('MapContainer', MapContainer)
Vue.component('MmapContainer', M_mapContainer)
Vue.use(VueTouch, { name: "v-touch" });

//时间转换
Vue.filter('timestampToTime', function (timestamp) {
  // 将时间戳转换为Date对象
  const date = new Date(timestamp);
  // 获取年、月、日、小时、分钟和秒
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  // 格式化时间
  const formattedTime = `${year}-${month}-${day}`;

  return formattedTime;
});

Vue.filter('hiddenDiv', function (title) {
  console.log(window.innerWidth)
  const screenWidth = window.innerWidth
  if (screenWidth < 1086) {
    title = title.substring(0, 20) + '...'
  }
  return title;
});

//过滤 <> 这两个
Vue.filter('filterTagsContent', function (value) {
  if (!value) return '';
  return value.replace(/<[^>]+>|<br\s*\/?>/g, function (match) {
    if (match === '<br>' || match === '<br />') {
      return '\n';
    } else {
      return '';
    }
  });
});
//过滤 /最新/ 这两个
Vue.filter('filterNews', function (str) {
  const arr = str.split(',')

  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (!item.includes('最新')) {
      newArr.push(item)
    }
  }

  if (newArr.length === 0) {
    newArr.push('集团要闻')
  }

  return newArr[0]
})

Vue.prototype.formatTimestamp = function (timestamp) {
  const date = timestamp ? new Date(timestamp) : new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  return `${year}-${month}-${day}`;

},
  Vue.prototype.$api = api
new Vue({
  el: '#app',
  router,
  store,
  data: {
    title: '浙江中峪控股集团',
    keywords: '浙江中峪控股集团官网,中峪控股集团',
    description: '浙江中峪控股集团有限公司是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。'
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: 'keywords',
          content: this.keywords
        },
        {
          name: 'description',
          content: this.description
        },
      ],
    }
  },
  render: h => h(app),
  mounted() {
    document.dispatchEvent(new Event("render-event"))
  }
});
