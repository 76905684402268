// import { ClassifiedNews } from './journalism.js'
import request from '@/util/request.js'
import { userLogin, ClassifiedNews } from './login.js'
export function getSignature(params) {
    return request({
        url: `/cms/api/wechat/getSignature`,
        method: 'get',
        params
    })
}
export default {
    ClassifiedNews,
    userLogin,
    getSignature
}