import request from '@/util/request.js'
//获取新闻种类
export function userLogin(params) {
    return request({
        url: `/api/admin/login`,
        method: 'post',
        params
    })
}
export function getGroupMemorabilia(params) {
    return request({
        url: `/cms/api/app/news/list`,
        method: 'get',
        params
    })
}
export function getBannerList(params) {
    return request({
        url: `/cms/api/app/banner/list`,
        method: 'get',
        params
    })
}

// ClassifiedNews
export function ClassifiedNews(id) {
    return request({
        url: `/api/admin/posts?status=PUBLISHED&page=0&size=6&categoryld=${id}`,
        method: 'get',
    })
}
export function developmentHistoryList(params) {
    return request({
        url: `/cms/api/app/developmentHistory/list`,
        method: 'get',
        params
    })
}


